const React = require('react');
const Ever = require('evergreen-ui');
const ReactDOM = require('react-dom');
const ChartJS = require('chart.js');
//const client = require('./client');
//const follow = require('./follow'); // function to hop multiple links by "rel"
//const root = '/';

class App extends React.Component {

    constructor(properties) {
        super(properties);
        this.changeStateData = this.changeStateData.bind(this);
        this.updateNewTextValue = this.updateNewTextValue.bind(this);
        this.createNewTodo = this.createNewTodo.bind(this);
        this.toggleTodo = this.toggleTodo.bind(this);
        this.todoTableRows = this.todoTableRows.bind(this);
        this.chartLoadTrend = this.chartLoadTrend.bind(this);
        this.allLocations = this.allLocations.bind(this);
        this.locationChanged = this.locationChanged.bind(this);

        this.bgColorStyle = {
            backgroundColor: 'rgb(255, 255, 255)'
        }
        this._seed = 1000;
        this.chartColors = {
            red: 'rgb(255, 99, 132)',
            orange: 'rgb(255, 159, 64)',
            yellow: 'rgb(255, 205, 86)',
            green: 'rgb(75, 192, 192)',
            blue: 'rgb(54, 162, 235)',
            purple: 'rgb(153, 102, 255)',
            grey: 'rgb(201, 203, 207)',
            black: 'rgb(0, 0, 0)',
            red2: 'rgb(200, 50, 50)',
            blue2: 'rgb(50, 50, 220)',
        };
        this.dynamicColors = [
            this.chartColors.red,
            this.chartColors.orange,
            this.chartColors.yellow,
            this.chartColors.green,
            this.chartColors.blue,
            this.chartColors.purple,
            this.chartColors.grey,
            this.chartColors.black,
            this.chartColors.red2,
            this.chartColors.blue2,
        ];

        this.state = {
            load: true,
            selectedLocation : 1,
            locations: [],
            chartTrend : {},
            chartMarket : {},
            chartFactorLine : {},
            chartTrendLineFlats : {},
            chartMarketLineFlats : {},
            chartTrendLineHouses : {},
            chartMarketLineHouses : {},
            chartDistributionBuyFlats : {},
            chartDistributionRentFlats : {},
            chartDistributionRoomsFlats : {},
            lineChartRoomsDistributionDataFlats: {
                labels: ['1 Room','2 Rooms','3 Rooms','4 Rooms','5 Rooms','6 Rooms'],
                datasets: [{
                    label: 'Buy (Price m²)',
                    backgroundColor: this.chartColors.red,
                    borderColor: this.chartColors.red,
                    lineTension: 0,
                    data: [],
                    fill: false,
                    yAxisID: 'y-axis-1',
                },
                    {
                        label: 'Rent (Price m²)',
                        backgroundColor: this.chartColors.blue,
                        borderColor: this.chartColors.blue,
                        lineTension: 0,
                        data: [],
                        fill: false,
                        yAxisID: 'y-axis-2',
                    }]
            },
            lineChartTrendDataFlats: {
                labels: [],
                datasets: [{
                    label: 'Buy (Price m²)',
                    backgroundColor: this.chartColors.red,
                    borderColor: this.chartColors.red,
                    lineTension: 0,
                    data: [],
                    fill: false,
                    yAxisID: 'y-axis-1',
                },
                    {
                        label: 'Rent (Price m²)',
                        backgroundColor: this.chartColors.blue,
                        borderColor: this.chartColors.blue,
                        lineTension: 0,
                        data: [],
                        fill: false,
                        yAxisID: 'y-axis-2',
                    }]
            },
            lineChartMarketDataFlats: {
                labels: [],
                datasets: [{
                    label: 'Buy (Count)',
                    backgroundColor: this.chartColors.red,
                    borderColor: this.chartColors.red,
                    lineTension: 0,
                    data: [],
                    fill: false,
                    yAxisID: 'y-axis-2',
                },
                    {
                        label: 'Rent (Count)',
                        backgroundColor: this.chartColors.blue,
                        borderColor: this.chartColors.blue,
                        lineTension: 0,
                        data: [],
                        fill: false,
                        yAxisID: 'y-axis-2',
                    }]
            },
            lineChartFactorData: {
                labels: [],
                datasets: [{
                    label: 'Flats (Years)',
                    backgroundColor: this.chartColors.purple,
                    borderColor: this.chartColors.purple,
                    lineTension: 0,
                    data: [],
                    fill: false,
                    yAxisID: 'y-axis-2',
                },
                    {
                        label: 'Houses (Years)',
                        backgroundColor: this.chartColors.green,
                        borderColor: this.chartColors.green,
                        lineTension: 0,
                        data: [],
                        fill: false,
                        yAxisID: 'y-axis-2',
                    }]
            },

            lineChartTrendDataHouses: {
                labels: [],
                datasets: [{
                    label: 'Buy (Price m²)',
                    backgroundColor: this.chartColors.red,
                    borderColor: this.chartColors.red,
                    lineTension: 0,
                    data: [],
                    fill: false,
                    yAxisID: 'y-axis-1',
                },
                    {
                        label: 'Rent (Price m²)',
                        backgroundColor: this.chartColors.blue,
                        borderColor: this.chartColors.blue,
                        lineTension: 0,
                        data: [],
                        fill: false,
                        yAxisID: 'y-axis-2',
                    }]
            },
            lineChartMarketDataHouses: {
                labels: [],
                datasets: [{
                    label: 'Buy (Count)',
                    backgroundColor: this.chartColors.red,
                    borderColor: this.chartColors.red,
                    lineTension: 0,
                    data: [],
                    fill: false,
                    yAxisID: 'y-axis-2',
                },
                    {
                        label: 'Rent (Count)',
                        backgroundColor: this.chartColors.blue,
                        borderColor: this.chartColors.blue,
                        lineTension: 0,
                        data: [],
                        fill: false,
                        yAxisID: 'y-axis-2',
                    }]
            },
            distributionBuyFlats : {
                type: 'doughnut',
                data: [],
                options: {
                    responsive: true,
                    title: {
                        display: true,
                        text: 'Buy Distribution (Flats)',
                    }
                },
            },
            distributionRentFlats : {
                type: 'doughnut',
                data: [],
                options: {
                    responsive: true,
                    title: {
                        display: true,
                        text: 'Rent Distribution (Flats)',
                    }
                },
            },

        }
    }

    loadFromServer() {
        fetch("/locations")
            .then(response => response.json())
            .then(result => {
                this.setState({locations : result});
                this.state.selectedLocation = this.state.locations[0].key;
                this.loadChartData();
            })
    }



    componentDidMount() {
        this.loadFromServer();
        this.chartLoadTrend();
        this.chartLoadMarket();
        this.chartLoadDistribution();
        this.chartLoadRoomDistribution();
        this.chartLoadFactor();
    }

    rand(min, max) {
        let seed = this._seed;
        min = min === undefined ? 0 : min;
        max = max === undefined ? 1 : max;
        this._seed = (seed * 9301 + 49297) % 233280;
        return min + (this._seed / 233280) * (max - min);
    }

    randomScalingFactor() {
        return Math.round(this.rand(-100, 100));
    };

    createNewTodo() {
        if (!this.state.todoItems
            .find(item => item.action === this.state.newItemText)) {
            this.setState({
                todoItems: [...this.state.todoItems,
                    { action: this.state.newItemText, done: false }],
                newItemText: ""
            });
        }
    }
    updateNewTextValue (event) {
        this.setState({ newItemText: event.target.value });
    }

    changeStateData() {
        this.setState({userName: this.state.userName === "Adam" ? "Bob" : "Adam" })
    }

    toggleTodo(todo) {
        this.setState({
            todoItems:
                this.state.todoItems.map(item =>
                    item.action === todo.action ? {...item, done: !item.done} : item)
        });
    };

    todoTableRows () {
        return (
            this.state.todoItems.map(item =>
                <tr key={item.action}>
                    <td>{item.action}</td>
                    <td>
                        <Ever.Checkbox type="checkbox" checked={ item.done } onChange={ () => this.toggleTodo(item)} />
                    </td>
                </tr>))
    }

    allLocations () {
        return (
            this.state.locations.map(item =>
                <option value={item.key}>{item.name}</option>)
        )
    }

    chartLoadTrend() {
        let trendChartFlats = document.getElementById('trendChartFlats').getContext('2d')
        this.state.chartTrendLineFlats = ChartJS.Chart.Line(trendChartFlats, {
            data: this.state.lineChartTrendDataFlats,
            options: {

                responsive: true,
                hoverMode: 'index',
                stacked: false,
                title: {
                    display: true,
                    text: 'Price development (Flats)'
                },
                scales: {
                    yAxes: [{
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                    }, {
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                        // grid line settings
                        gridLines: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                    }],
                }
            }
        });
        let trendChartHouses = document.getElementById('trendChartHouses').getContext('2d')
        this.state.chartTrendLineHouses = ChartJS.Chart.Line(trendChartHouses, {
            data: this.state.lineChartTrendDataHouses,
            options: {

                responsive: true,
                hoverMode: 'index',
                stacked: false,
                title: {
                    display: true,
                    text: 'Price development (Houses)'
                },
                scales: {
                    yAxes: [{
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                    }, {
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                        // grid line settings
                        gridLines: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                    }],
                }
            }
        });
    };

    chartLoadMarket() {
        let marketChartFlats = document.getElementById('marketChartFlats').getContext('2d')
        this.state.chartMarketLineFlats = ChartJS.Chart.Line(marketChartFlats, {
            data: this.state.lineChartMarketDataFlats,
            options: {

                responsive: true,
                hoverMode: 'index',
                stacked: false,
                title: {
                    display: true,
                    text: 'Supply on the Market (Flats)'
                },
                scales: {
                    yAxes: [{
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                    }],
                }
            }
        });
        let marketChartHouses = document.getElementById('marketChartHouses').getContext('2d')
        this.state.chartMarketLineHouses = ChartJS.Chart.Line(marketChartHouses, {
            data: this.state.lineChartMarketDataHouses,
            options: {

                responsive: true,
                hoverMode: 'index',
                stacked: false,
                title: {
                    display: true,
                    text: 'Supply on the Market (Houses)'
                },
                scales: {
                    yAxes: [{
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                    }],
                }
            }
        });
    };

    chartLoadRoomDistribution() {
        let distributionRoomsChartFlats = document.getElementById('distributionRoomsChartFlats').getContext('2d')
        this.state.chartDistributionRoomsFlats = ChartJS.Chart.Line(distributionRoomsChartFlats, {
            data: this.state.lineChartRoomsDistributionDataFlats,
            options: {

                responsive: true,
                hoverMode: 'index',
                stacked: false,
                title: {
                    display: true,
                    text: 'Price distribution by rooms (Flats)'
                },
                scales: {
                    yAxes: [{
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                    }, {
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                        // grid line settings
                        gridLines: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                    }],
                }
            }
        });
    }

    chartLoadDistribution() {
        let distributionChartBuyFlats = document.getElementById('distributionChartBuyFlats').getContext('2d')
        this.state.chartDistributionBuyFlats = new ChartJS.Chart(distributionChartBuyFlats, this.state.distributionBuyFlats);

        let distributionChartRentFlats = document.getElementById('distributionChartRentFlats').getContext('2d')
        this.state.chartDistributionRentFlats = new ChartJS.Chart(distributionChartRentFlats, this.state.distributionRentFlats);

    }

    chartLoadFactor() {
        let factorChart = document.getElementById('factorChart').getContext('2d')
        this.state.chartFactorLine = ChartJS.Chart.Line(factorChart, {
            data: this.state.lineChartFactorData,
            options: {
                responsive: true,
                hoverMode: 'index',
                stacked: false,
                title: {
                    display: true,
                    text: 'Return of investment'
                },
                scales: {
                    yAxes: [{
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                    }],
                }
            }
        });
    }
    loadChartData() {
        this.setState({load : true});
        fetch("/prices/"+this.state.selectedLocation)
            .then(response => response.json())
            .then(result => {
                this.state.lineChartTrendDataFlats.labels = result.Dates;
                this.state.lineChartTrendDataHouses.labels = result.Dates;
                this.state.lineChartMarketDataFlats.labels = result.Dates;
                this.state.lineChartMarketDataHouses.labels = result.Dates;
                this.state.lineChartFactorData.labels = result.Dates;

                const distributionBuyFlatsData = {
                    labels: [],
                    datasets: [{
                        backgroundColor: [],
                        data: [],
                    }],
                };

                const distributionRentRooms = [null,null,null,null,null,null];
                const distributionBuyRooms = [null,null,null,null,null,null];

                const distributionRentFlatsData = {
                    labels: [],
                    datasets: [{
                        backgroundColor: [],
                        data: [],
                    }],
                };

                for (var i = 0; i < result.Entries.length; i++) {
                    if (result.Entries[i].ImmoType=="Flat") {
                        if (result.Entries[i].PriceType=="Buy") {
                            if (result.Entries[i].Rooms!=null && result.Entries[i].Rooms<7) {
                                distributionBuyFlatsData.datasets[0].backgroundColor.push(this.dynamicColors[distributionBuyFlatsData.labels.length % this.dynamicColors.length]);
                                distributionBuyFlatsData.datasets[0].data.push(result.Entries[i].Elements[result.Entries[i].Elements.length-1]);
                                distributionBuyFlatsData.labels.push(result.Entries[i].Rooms + ' Rooms');
                                distributionBuyRooms[result.Entries[i].Rooms-1] = result.Entries[i].Prices[result.Entries[i].Prices.length-1];
                            }
                        } else if (result.Entries[i].PriceType=="Rent") {
                            if (result.Entries[i].Rooms!=null && result.Entries[i].Rooms<7) {
                                distributionRentFlatsData.datasets[0].backgroundColor.push(this.dynamicColors[distributionRentFlatsData.labels.length % this.dynamicColors.length]);
                                distributionRentFlatsData.datasets[0].data.push(result.Entries[i].Elements[result.Entries[i].Elements.length-1]);
                                distributionRentFlatsData.labels.push(result.Entries[i].Rooms + ' Rooms');
                                distributionRentRooms[result.Entries[i].Rooms-1] = result.Entries[i].Prices[result.Entries[i].Prices.length-1];
                            }
                        }

                    }

                    if (result.Entries[i].ImmoType=="Flat" &&
                        result.Entries[i].PriceType=="Buy" &&
                        result.Entries[i].Rooms==null) {
                        this.state.lineChartTrendDataFlats.datasets[0].data = result.Entries[i].Prices;
                        this.state.lineChartMarketDataFlats.datasets[0].data = result.Entries[i].Elements;
                    } else if (result.Entries[i].ImmoType=="Flat" &&
                        result.Entries[i].PriceType=="Rent" &&
                        result.Entries[i].Rooms==null) {
                        this.state.lineChartTrendDataFlats.datasets[1].data = result.Entries[i].Prices
                        this.state.lineChartMarketDataFlats.datasets[1].data = result.Entries[i].Elements;
                    } else {
                        if (result.Entries[i].ImmoType=="House" &&
                            result.Entries[i].PriceType=="Buy" &&
                            result.Entries[i].Rooms==null) {
                            this.state.lineChartTrendDataHouses.datasets[0].data = result.Entries[i].Prices;
                            this.state.lineChartMarketDataHouses.datasets[0].data = result.Entries[i].Elements;
                        } else if (result.Entries[i].ImmoType=="House" &&
                            result.Entries[i].PriceType=="Rent" &&
                            result.Entries[i].Rooms==null) {
                            this.state.lineChartTrendDataHouses.datasets[1].data = result.Entries[i].Prices
                            this.state.lineChartMarketDataHouses.datasets[1].data = result.Entries[i].Elements;
                        }
                    }
                }

                var roiFlats = [];
                var roiHouses = [];
                // Calculate return of investments
                for (var i=0;i<result.Dates.length;i++) {
                    if (this.state.lineChartTrendDataFlats.datasets[0].data[i]==null ||
                        this.state.lineChartTrendDataFlats.datasets[1].data[i]==null) {
                        roiFlats.push(null);
                    } else {
                        roiFlats.push(this.state.lineChartTrendDataFlats.datasets[0].data[i]/
                            this.state.lineChartTrendDataFlats.datasets[1].data[i]/12.0)
                    }

                    if (this.state.lineChartTrendDataHouses.datasets[0].data[i]==null ||
                        this.state.lineChartTrendDataHouses.datasets[1].data[i]==null) {
                        roiHouses.push(null);
                    } else {
                        roiHouses.push(this.state.lineChartTrendDataHouses.datasets[0].data[i]/
                            this.state.lineChartTrendDataHouses.datasets[1].data[i]/12.0)
                    }
                }


                this.setState({chartData : result});
                this.setState({load : false});

                this.state.lineChartFactorData.datasets[0].data = roiFlats;
                this.state.lineChartFactorData.datasets[1].data = roiHouses;

                this.state.lineChartRoomsDistributionDataFlats.datasets[0].data=distributionBuyRooms;
                this.state.lineChartRoomsDistributionDataFlats.datasets[1].data=distributionRentRooms;

                this.state.distributionBuyFlats.data=distributionBuyFlatsData;
                this.state.distributionRentFlats.data=distributionRentFlatsData;

                this.state.chartTrendLineFlats.update();
                this.state.chartMarketLineFlats.update()
                this.state.chartTrendLineHouses.update();
                this.state.chartMarketLineHouses.update()

                this.state.chartDistributionBuyFlats.update()
                this.state.chartDistributionRentFlats.update()
                this.state.chartDistributionRoomsFlats.update()

                this.state.chartFactorLine.update()


            })
    }


    locationChanged(event) {
        this.state.selectedLocation = event.target.value;
        this.setState({ selectedLocation: event.target.value})
        // Request Data
        this.loadChartData();
    }


    spin() {
        if (this.state.load) {
            return (<Ever.Spinner size={30}/>)
        }
    }


    render() {
        return (
            <div>
                <Ever.Pane display="flex" width={240}>
                    <Ever.Select width={240} marginBottom={5} onChange={ this.locationChanged }>
                        { this.allLocations() }
                    </Ever.Select>
                    { this.spin() }
                </Ever.Pane>
                <Ever.Pane display="flex">
                    <table width="100%">
                        <tr><td width="50%"><canvas id="trendChartFlats" width={100} style={this.bgColorStyle}></canvas></td>
                            <td width="50%"><canvas id="marketChartFlats" width={100} style={this.bgColorStyle}></canvas></td></tr>
                        <tr><td width="50%"><canvas id="trendChartHouses" width={100} style={this.bgColorStyle}></canvas></td>
                            <td width="50%"><canvas id="marketChartHouses" width={100} style={this.bgColorStyle}></canvas></td></tr>
                        <tr><td width="50%"><canvas id="distributionChartBuyFlats" width={100} style={this.bgColorStyle}></canvas></td>
                            <td width="50%"><canvas id="distributionChartRentFlats" width={100} style={this.bgColorStyle}></canvas></td></tr>
                        <tr><td width="50%"><canvas id="distributionRoomsChartFlats" width={100} style={this.bgColorStyle}></canvas></td>
                            <td width="50%"><canvas id="factorChart" width={100} style={this.bgColorStyle}></canvas></td></tr>
                    </table>
                </Ever.Pane>
            </div>
        )
    }


    hide() {
        console.log("test");
    }
}

export default App;

ReactDOM.render(<App />, document.getElementById('root'));
